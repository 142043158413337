const HttpBackend = require('i18next-http-backend/cjs');

module.exports = {
  i18n: {
    defaultLocale: 'en-US',
    locales: ['en-US', 'fr'],
  },

  backend: {
    loadPath: `${process.env.RESOURCE_PATH}api/umbraco/api/Dictionary/GetDictionaryEntries?language={{lng}}`,
    overrideMimeType: true,
    expirationTime: process.env.NODE_ENV === 'development' ? 0 : 30 * 60 * 1000, // 30 minutes
  },

  reloadOnPrerender: process.env.NODE_ENV === 'development',
  ns: ['common'],
  defaultNS: 'common',
  use: [HttpBackend],
  debug: false,
  serializeConfig: false,
  fallbackLng: false,
};
